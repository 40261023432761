//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import VueSlickCarousel from 'vue-slick-carousel'

export default {
    data() {
        return {
            width: null,
            timeout: null,
            settings: {
                arrows: true,
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 1,
                // autoplay: true,
                // autoplaySpeed: 5000,
                // adaptiveHeight: true,
                // focusOnSelect: true,
                // centerMode: true,
                variableWidth: true,
                prevArrow: '#prevArrow',
                nextArrow: '#nextArrow',
                responsive: [{
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 4,
                    }
                },{
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                    }
                },{
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                    }
                },{
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            }
        }
    },
    components: {
        VueSlickCarousel,
    },
    props: {
        blok: {
            type: Object,

            default: () => { }
        }
    },
    mounted() {
        let elements = [{ 
            elem: '.typewriter-text',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        // window.addEventListener('load', this.returnanimElements.bind(this, elements))
        // this.animElements.bind(this, elements)
    },
    unmounted () {
        let elements = [{ 
            elem: '.typewriter-text',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        // window.addEventListener('load', this.returnanimElements.bind(this, elements))
        // this.animElements.bind(this, elements)
    },
    methods: {
        setOpacity(hex, alpha) {
            return `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`
        },
        isElementXPercentInViewport(el, percentVisible) {
            let rect = el.getBoundingClientRect(),
                windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;

            return !(
                Math.floor(
                    100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
                ) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
                percentVisible
            );
        },
        returnanimElements(elemntsAnim) {
            
            if (elemntsAnim.length > 0) {
                elemntsAnim.forEach((item) => {
                    var el = document.querySelectorAll(item.elem);
                    
                    if (el.length > 0) {
                        // window.addEventListener('load', (e) => {
                        el.forEach((element) => {
                            if (this.isElementXPercentInViewport(element, item.percentage)) {
                                element.classList.add("in_view");
                            } else {
                                element.classList.remove("in_view");
                            }
                        });
                    }
                });
            }
        },
    },
    computed: {

    }
};
